import React from 'react'
import {Helmet} from "react-helmet";

const Url = () => {
  return (
    <Helmet>
    <script>window.location = "https://booking.spaxzs.com?s=XX6158"</script>
    </Helmet>
  )
}

export default Url